import styled, { keyframes } from 'styled-components';
import {
  COLORS,
  BREAKPOINTS,
  Logo as LogoBackground,
  MenuButton as MenuButtonBackground,
} from '../../utils/theme';

export const HomeHeader = styled.section`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: ${COLORS.WHITE};

  ${(props) =>
    props.src &&
    `
        background: url(${props.src}), rgba(0,0,0,0.5);
    `}
`;

export const SectionContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Logo = styled(LogoBackground)`
  transition: background-position 0.4s;
  background-position: 20px -100%;

  ${(props) => (props.fixedBackgroundAttachment === false ? 'background-attachment: unset;' : '')}
  ${(props) => ` ${props.visible ? 'background-position: 20px 23px;' : ''}`}

  ${(props) =>
    !props.narrow &&
    `
    @media (min-width: ${BREAKPOINTS.PHABLET}px) {
      background-position: 60px -100%;
      ${props.visible ? 'background-position: 60px 38px;' : ''}
    }
  `}
`;

export const TitleNextToLogo = styled.p`
  margin: 0;

  position: absolute;
  transform: translateY(-50%);
  top: 36px;
  left: 64px;

  z-index: 100;

  font-size: 14px;
  font-weight: 500;

  max-width: calc(100% - 13rem);

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 24px;
    font-weight: 500;
  }
`;

export const MenuButton = styled(MenuButtonBackground)`
  transition: background-position 0.4s;
  background-position: calc(100% - 20px) -100%;

  ${(props) => (props.fixedBackgroundAttachment === false ? 'background-attachment: unset;' : '')}
  ${(props) => props.visible && `background-position: calc(100% - 20px) 20px;`}

  ${(props) =>
    !props.narrow &&
    `
    
    @media (min-width: ${BREAKPOINTS.PHABLET}px) {
      background-position: calc(100% - 40px) -100%;
      ${props.visible && 'background-position: calc(100% - 40px) 38px;'}
    }
    `}
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const Image1 = styled.img``;

export const Image2 = styled.img``;

export const HomeHeaderText = styled.h2`
  position: relative;
  margin: 0;
  font-size: 50px;
  font-weight: normal;
  text-align: center;
  max-width: 975px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    font-size: 34px;
    max-width: 650px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 24px;
    max-width: 400px;
  }
`;

const dash = keyframes`
  from {
    stroke-dashoffset: 1;
  }
  to {
    stroke-dashoffset: 0;
  }
`;

export const ImgArtLines = styled.svg`
  position: absolute;
  width: 92px;
  top: 55px;
  left: 260px;

  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: ${dash} 3s cubic-bezier(0.37, 0, 0.63, 1) forwards 2s;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 68px;
    top: 36px;
    left: 175px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 43px;
    top: 56px;
    left: 103px;
  }
`;

export const UserHasToBeLoggedIn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.12;
  font-size: 30px;
  color: ${COLORS.BLACK};
  min-height: 300px;
  padding-top: 125px;
`;

export const ImgConnectLines = styled.svg`
  position: absolute;
  width: 180px;
  left: 525px;
  top: 170px;

  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: ${dash} 2.5s cubic-bezier(0.37, 0, 0.63, 1) forwards 3.5s;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 112px;
    left: 362px;
    top: 120px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 93px;
    left: 205px;
    top: 110px;
  }
`;

export const ImgExistLines = styled.svg`
  position: absolute;
  width: 116px;
  bottom: -65px;
  right: 210px;

  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: ${dash} 1.9s cubic-bezier(0.85, 0, 0.15, 1) forwards 4.8s;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 90px;
    bottom: -48px;
    right: 135px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 68px;
    bottom: -40px;
    right: 55px;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;
