import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import qs from 'qs';

import { COLORS } from '../../../utils/theme';
import useMousePosition from '../../../lib/useMousePosition';

import {
  Container,
  Title,
  ArtQuality as StyledArtQuality,
  ArtQualityImg,
  Type,
  Desc,
  ArtQualityMobileSelected,
  ArtQualityMobileBackground,
  ArtQualityMobileCategories,
  ArtQualityTag,
  MobileTagContainer,
  MobileTag,
  Tag,
} from './styles';

import { contentFetch } from '../../../lib/content-fetcher';

import { ART_QUALITIES_STYLES } from './constants';

const ArtQualitityMobile = ({ artQualities }) => {
  const [artQualitySelectedIdx, setArtQualitySelectedIdx] = useState(0);

  return (
    <>
      <ArtQualityMobileSelected
        href={
          artQualities[artQualitySelectedIdx]?.attributes?.link
            ? artQualities[artQualitySelectedIdx]?.attributes?.link
            : null
        }
        as={artQualities[artQualitySelectedIdx]?.attributes?.link ? 'a' : 'div'}
      >
        <ArtQualityMobileBackground />
        <ArtQualityMobileBackground />
        <ArtQualityMobileBackground />
        <ArtQualityMobileBackground />
        <Type>{artQualities[artQualitySelectedIdx]?.attributes?.name}</Type>
        <Desc maxWidth={ART_QUALITIES_STYLES[artQualitySelectedIdx]?.maxWidthDesc}>
          {artQualities?.[artQualitySelectedIdx]?.attributes?.description}
        </Desc>
        <MobileTagContainer>
          <MobileTag isLink={!!artQualities[artQualitySelectedIdx]?.attributes?.link}>
            {artQualities[artQualitySelectedIdx].attributes?.link
              ? 'Click to explore'
              : 'Coming soon'}
          </MobileTag>
        </MobileTagContainer>
      </ArtQualityMobileSelected>
      <ArtQualityMobileCategories>
        {artQualities?.length
          ? artQualities.map((artQuality, idx) => (
              <ArtQualityTag
                key={idx}
                selected={artQualitySelectedIdx === idx}
                onClick={() => setArtQualitySelectedIdx(idx)}
              >
                {artQuality?.attributes?.name}
              </ArtQualityTag>
            ))
          : null}
      </ArtQualityMobileCategories>
    </>
  );
};

const ArtQuality = ({ artQuality, idx, onMouseEnter, onMouseLeave }) => {
  return (
    <StyledArtQuality
      {...ART_QUALITIES_STYLES[idx]}
      href={artQuality?.attributes?.link ? artQuality.attributes.link : null}
      as={artQuality?.attributes?.link ? 'a' : 'div'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Type>{artQuality?.attributes?.name}</Type>
      <Desc maxWidth={ART_QUALITIES_STYLES[idx]?.maxWidthDesc}>
        {artQuality?.attributes.description}
      </Desc>
      <ArtQualityImg
        {...ART_QUALITIES_STYLES[idx]}
        imgUrl={
          artQuality?.attributes?.image?.data?.attributes?.formats?.large?.url ||
          artQuality?.attributes?.image?.data?.attributes?.url
        }
        source={artQuality?.attributes?.source}
      />
    </StyledArtQuality>
  );
};

const ArtQualities = () => {
  const [artQualities, setArtQualities] = useState([]);

  const windowMouse = useMousePosition();

  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [hasLink, setHasLink] = useState(false);

  const onMouseEnter = (artQuality) => {
    setIsMessageVisible(true);
    setHasLink(!!artQuality?.attributes?.link);
  };
  const onMouseLeave = () => {
    setIsMessageVisible(false);
  };

  const animatedTagProps = useSpring({
    top: windowMouse.y || 0,
    left: windowMouse.x || 0,
    opacity: isMessageVisible ? 1 : 0,
    background: hasLink ? COLORS.BLUE : COLORS.WHITE,
    color: hasLink ? COLORS.WHITE : COLORS.BLACK,
  });

  useEffect(() => {
    const query = qs.stringify(
      {
        populate: {
          image: {
            fields: ['url', 'formats'],
          },
        },
        sort: ['name:asc'],
      },
      {
        encodeValuesOnly: true,
      },
    );
    contentFetch({
      pathname: `/art-qualities?${query}`,
      setState: setArtQualities,
      transform: (d) => d?.data,
    });
  }, []);

  return (
    <>
      <Tag
        as={animated.div}
        style={{
          ...animatedTagProps,
        }}
      >
        {hasLink ? 'Click to explore' : 'Coming soon'}
      </Tag>
      <Container>
        <Title>Art Qualities</Title>
        {artQualities?.length
          ? artQualities.map((artQuality, idx) => (
              <ArtQuality
                key={idx}
                artQuality={artQuality}
                idx={idx}
                onMouseEnter={() => onMouseEnter(artQuality)}
                onMouseLeave={onMouseLeave}
              ></ArtQuality>
            ))
          : null}
        {artQualities.length ? <ArtQualitityMobile artQualities={artQualities} /> : null}
      </Container>
    </>
  );
};

export default ArtQualities;
