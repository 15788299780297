import * as React from 'react';
import loadable from '@loadable/component';

import { COLORS } from '../utils/theme';
import SEO from '../components/SEO';
import Background from '../components/Background';
import Header from '../components/Header';
import HeaderTitle from '../components/HeaderTitle';
import WhyArtMatter from '../components/ArtQualities/WhyArtMatter';
import FeatureOngoingResearch from '../components/ArtQualities/FeatureOngoingResearch';

import { contentFetch } from '../lib/content-fetcher';
import { PAGES_TYPES } from '../utils/constants';

const Footer = loadable(() => import('../components/Footer'));

const ArtQualitiesPage = () => {
  const [menuIsShown, showMenu] = React.useState(false);

  const [ongoingResearches, setOngoingResearches] = React.useState([]);
  React.useEffect(() => {
    contentFetch({
      pathname: '/ongoing-researches',
      setState: setOngoingResearches,
      transform: (d) => d.data,
    });
  }, []);

  return (
    <Background color={COLORS.BACKGROUND_LIGHT}>
      <SEO title="Art Qualities"></SEO>
      <Header menuIsShown={menuIsShown} showMenu={showMenu} />
      <HeaderTitle menuIsShown={menuIsShown} type={PAGES_TYPES.ART} />
      <WhyArtMatter menuIsShown={menuIsShown} />
      <FeatureOngoingResearch menuIsShown={menuIsShown} ongoingResearches={ongoingResearches} />
      <Footer />
    </Background>
  );
};

export default ArtQualitiesPage;
