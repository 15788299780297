import { MEMBER_TYPES } from '../utils/constants';

export const groupBySize = (array, groupSize) => {
  if (!array?.length) return array;
  return array
    .map((item, index) => {
      return index % groupSize === 0 ? array.slice(index, index + groupSize) : null;
    })
    .filter(function (item) {
      return item;
    });
};

export const openNewTab = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};

export const generateId = (length) => {
  var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
};

export function resetZoom(cy, zoomSetting, type = 'fit') {
  const nodeToCenter = cy?.getElementById('center');

  if (type === 'fit') {
    cy?.fit(null, 80);
  } else {
    cy?.zoom(zoomSetting.INITIAL_ANIMATION_END);
    cy?.center(nodeToCenter);
  }
}

export function formatListReadable(arr) {
  if (typeof Intl !== 'undefined' && Intl.ListFormat) {
    const lf = new Intl.ListFormat('en');
    return lf.format(arr);
  } else {
    // Fallback logic if Intl.ListFormat is not available
    return arr.join(', ');
  }
}

export function normalizeSortName(name) {
  return name
    .trim()
    .replace(/[^\p{L}\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}#]/gu, '')
    .toLowerCase();
}

export function groupedAndSortedUsersByLetters(users, dataAttr) {
  const grouped = users.reduce((acc, obj) => {
    const firstLetter = normalizeSortName(dataAttr ? obj.data.sortName : obj.sortName)
      .charAt(0)
      .toLowerCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(obj);
    return acc;
  }, {});

  const sortedGroups = Object.keys(grouped)
    .sort()
    .map((letter) => {
      return grouped[letter].sort((a, b) =>
        normalizeSortName(dataAttr ? a.data.sortName : a.sortName).localeCompare(
          normalizeSortName(dataAttr ? b.data.sortName : b.sortName),
        ),
      );
    });

  return { grouped, sortedGroups };
}

const filterOtherProfile = (array = [], otherProfile) => {
  const filteredArray = array
    .filter((profile) => profile?.name !== 'Other')
    .map((profile) => profile.name);

  if (otherProfile) filteredArray.push(otherProfile);

  return filteredArray;
};

export const getProfileDescription = (user) => {
  const userIndividualProfiles = filterOtherProfile(
    user?.individualProfiles,
    user?.individualProfilesOther,
  );
  const organizationlProfiles = filterOtherProfile(
    user?.organizationType,
    user?.organizationTypeOther,
  );

  return user?.typeOfMember?.label === MEMBER_TYPES.INDIVIDUAL
    ? formatListReadable(userIndividualProfiles)
    : formatListReadable(organizationlProfiles);
};
